import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { setAuthUser, getUserFromStorage, removeUserFromStorage, updateUser } from 'utils/auth';
import useLogin from 'hooks/useLogin';
import { Paths } from 'routes';
import useUserDetails from 'hooks/userDetailsHooks';

const AuthContext = React.createContext();

const AuthProvider = (props) => {
  const [loginUser, loginInfo] = useLogin();
  const { remove } = useUserDetails();
  const [user, setUser] = useState(null);
  const history = useHistory();

  const saveUserToken = React.useCallback((data) => {
    setAuthUser(data);
  }, []);

  const login = React.useCallback(
    (form) =>
      loginUser(form).then((response) => {
        saveUserToken(response);
        history.push(Paths.SPLASH_SCREEN);
      }),
    [loginUser, history, saveUserToken],
  );

  const logout = React.useCallback(() => {
    removeUserFromStorage();
    setUser(null);
    history.push('/');
    remove();
  }, [history, remove]);

  const updateUserDetails = React.useCallback((data) => {
    const userData = updateUser(data);
    setUser(userData);
  }, []);

  useEffect(() => {
    const userData = getUserFromStorage();
    if (userData) setUser(userData);
  }, []);

  const userData = user || getUserFromStorage();

  return (
    <AuthContext.Provider
      value={{
        loginInfo,
        login,
        user: userData,
        updateUserDetails,
        logout,
      }}
      {...props}
    />
  );
};

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
