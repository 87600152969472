import { client } from 'utils/api-client';
import { reg_type } from 'utils/constants';

export function loginApi(data) {
  return client(`auth/login/`, {
    method: 'POST',
    data: { ...data, email: data.email.toLowerCase() },
  }).then((res) => res);
}

export function fetchCurrentCitizen() {
  return client(`person/current/`).then((res) => res);
}

export function fetchCitizenById(_, citizenId) {
  return client(`person/${citizenId}/`);
}

export function fetchCurrentUser() {
  return client(`auth/users/me/`).then((res) => res);
}

export function registerApi(data) {
  data.registration_type = reg_type;
  return client(`auth/users/registration/`, { method: 'POST', data }).then((res) => res);
}

export function resetPasswordApi(data) {
  return client(`auth/users/reset-password/`, { method: 'POST', data }).then((res) => res);
}

export function createPasswordApi(data) {
  return client(`auth/users/create-password/`, { method: 'POST', data }).then((res) => res);
}

export function verifyTokenApi(data) {
  return client(`auth/users/verify-token/`, { method: 'POST', data }).then((res) => res);
}

export function resendLinkApi(data) {
  return client(`auth/users/verification/resend/`, { method: 'POST', data }).then((res) => res);
}
