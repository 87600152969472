import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { isUserAuthenticated } from 'utils/auth';

export default function PrivateRoute({ renderComponent, children, ...rest }) {
  const RenderComponent = renderComponent;
  return (
    <Route
      {...rest}
      render={({ location, ...props }) =>
        isUserAuthenticated() ? (
          <RenderComponent location={location} {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired,
};
