export const DATE_FORMAT = 'dd - LLL - yyyy';

export const IdentificationType = {
  'drivers liscense': 'drivers liscense',
  'international passport': 'international passport',
  'voters id': 'voters id',
  'national identity number': 'national identity number',
};

export const Gender = {
  MALE: `Male`,
  FEMALE: `Female`,
};

export const reg_type = process.env.REACT_APP_REG_TYPE || 'CITIZEN';

export const MaritalStatus = {
  SINGLE: `Single`,
  DIVORCED: `Divorced`,
  MARRIED: `Married`,
  WIDOWED: `WIDOWED`,
};

export const Religion = {
  NONE: `None`,
  CHRISTIANITY: `Christianity`,
  ISLAM: `Islam`,
  TRADITIONAL: `Traditional`,
  JUDAISM: `Judaism`,
  BUDDHISM: `Buddhism`,
  HINDUISM: `Hinduism`,
  OTHERS: `Others`,
};

export const EyeColor = {
  BROWN: `Brown`,
  BLUE: `Blue`,
  GREEN: `Green`,
  OTHERS: `OTHERS`,
};

export const StayDuration = {
  NEW_COMER: `< 1 YEAR`,
  JUNIOR: `1 - 2 YEARS`,
  MID: `3 - 5 YEARS`,
  SENIOR: `5 - 10 YEARS`,
  EXPERT: `> 10 YEARS`,
};

export const NokRelationship = {
  SISTER: `Sister`,
  BROTHER: `Brother`,
  PARENT: `Parent`,
  SPOUSE: `Spouse`,
  FRIEND: `Friend`,
  SON: `Son`,
  DAUGHTER: `Daughter`,
  RELATION: `Relation`,
  OTHERS: `Others`,
};

export const CitizenCategory = {
  GENERAL: `GENERAL`,
  OKADA: `OKADA`,
};

export const VehicleCategory = {
  motorcycles: `motorcycles`,
  tricycles: `tricycles`,
  cars: `cars`,
};

export const VehicleUse = {
  commercial: `commercial`,
  private: `private`,
};

export const HighestEducationQualification = {
  BASIC: `Basic`,
  O_LEVEL: `O Level`,
  OND: `OND`,
  HND: `HND`,
  BSC: `BSC`,
  MSC: `MSC`,
  PHD: `PHD`,
  RELIGIOUS_EDUCATION: `Religious Education`,
  OTHERS: `Others`,
};

export const PhysicalDisabilities = {
  VISION_IMPAIRMENT: `Vision Impairement`,
  DEAF_OR_DIFFICULTY_HEARING: `Deaf or Difficulty hearing`,
  DUMB_OR_DIFFICULTY_SPEAKING: `Dumb or Difficulty speaking`,
  MENTAL_HEALTH_CONDITIONS: `Mental health conditions`,
  INTELLECTUAL_DISABILITY: `Intellectual disability`,
  ACQUIRED_BRAIN_INJURY: `Acquired brain injury`,
  AUSTISM: `Autism`,
  SPECTRUM_DISORDER: `Spectrum disorder`,
  OTHERS: `Others`,
};

export const UserRoles = {
  CITIZEN: 'CITIZEN',
  ADMIN: 'ADMIN',
  AGENT: 'AGENT',
  OFFICER: 'OFFICER',
  EXECUTIVE: 'EXECUTIVE',
  AGENCY: 'AGENCY',
  SECURITY: 'SECURITY',
};

export const CitizenStatus = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const CitizenRegistrationStatus = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
};

export const AuditLogActions = {
  LOGIN: 'Log in',
  UPDATE: 'Data Update',
  REGISTER_CITIZEN: 'Register Citizen',
};

export const emailRegexPattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
