import React, { lazy, Suspense } from 'react';
import { Router, Switch, Route, useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';

import PrivateRoute from 'components/PrivateRoute';
import logoImg from 'assets/svg/oyo_logo.svg';
import { makeStyles } from '@material-ui/styles';
import SplashScreenView from 'screens/SplashScreenView';

export const Paths = {
  ADMIN: '/admin',
  CITIZENS: '/citizens',
  LOGIN: '/login',
  HOME: '/',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  SPLASH_SCREEN: '/splash-screen',
  REGISTRATION_CENTER: '/registration-centers',
  VERIFICATION: '/verification',
  CITIZEN_SUMMARY_DETAIL: '/citizen-summary/:id',
};

const PublicRoutes = [
  {
    path: Paths.HOME,
    component: lazy(() => import('screens/Home')),
    exact: true,
    route: Route,
  },
  {
    path: Paths.SPLASH_SCREEN,
    component: lazy(() => import('screens/SplashScreenView')),
    exact: true,
    route: Route,
  },
  {
    path: Paths.LOGIN,
    component: lazy(() => import('screens/Login')),
    exact: true,
    route: Route,
  },
  {
    path: Paths.FORGOT_PASSWORD,
    component: lazy(() => import('screens/ForgotPassword')),
    exact: false,
    route: Route,
  },
  {
    path: Paths.RESET_PASSWORD,
    component: lazy(() => import('screens/ResetPassword')),
    exact: true,
    route: Route,
  },
  {
    path: Paths.REGISTRATION_CENTER,
    component: lazy(() => import('screens/RegistrationCenters')),
    exact: false,
    route: Route,
  },
  {
    path: Paths.VERIFICATION,
    component: lazy(() => import('screens/VerifyCitizen')),
    exact: false,
    route: Route,
  },
  {
    path: Paths.CITIZEN_SUMMARY_DETAIL,
    component: lazy(() => import('screens/VerifyCitizen/CitizenSummary')),
    exact: false,
    route: Route,
  },
];

const Routes = () => {
  const history = useHistory();

  return (
    <SplashScreenView>
      <Suspense
        fallback={
          <Box style={{ height: '100vh', width: '100vw' }}>
            <FallBackScreen />
          </Box>
        }
      >
        <Router history={history}>
          <Switch>
            <PrivateRoute
              path={Paths.ADMIN}
              exact={false}
              renderComponent={lazy(() => import('./adminRoutes'))}
            />
            <Route
              path={Paths.CITIZENS}
              exact={false}
              component={lazy(() => import('./citizensRoute'))}
            />
            {PublicRoutes.map(({ path, component, layout, exact }, i) => {
              const Layout = layout;
              if (!!Layout)
                return (
                  <Layout>
                    <Route key={i} path={path} exact={exact} component={component} />
                  </Layout>
                );
              return <Route key={i} path={path} exact={exact} component={component} />;
            })}
          </Switch>
        </Router>
      </Suspense>
    </SplashScreenView>
  );
};

export const FallBackScreen = () => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100%', minWidth: '100%' }}
    >
      <img src={logoImg} alt="Oyo state logo" className={classes.logo} />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  logo: {
    height: '60px',
    animation: `$beat 2000ms infinite`,
  },
  '@keyframes beat': {
    '0%': {
      opacity: 0.9,
      transform: 'scale(0.9)',
    },
    '100%': {
      opacity: 1,
      transform: 'scale(1)',
    },
  },
}));

export default Routes;
