/**
 * This hook makes a request to the backend to get the user details, if the details isn't already in the cache and transorms it to match the role format defined in the Roles object.
 *  A role, user and isSucess can be destructed from this hook.
 **/
import { useQuery } from 'react-query';
import { fetchCurrentUser } from 'services/Auth';
import { isUserAuthenticated } from 'utils/auth';

export const useUserDetails = () => {
  const userDetailsQuery = useQuery({
    queryKey: 'userDetails',
    queryFn: fetchCurrentUser,
    config: {
      enabled: isUserAuthenticated(),
      cacheTime: 3600 * 100,
      staleTime: 3600 * 100,
    },
  });

  return {
    user: userDetailsQuery?.data?.data,
    isSuccess: userDetailsQuery.isSuccess,
    isError: userDetailsQuery.isError,
    error: userDetailsQuery.error,
    isLoading: userDetailsQuery.isLoading,
    refetch: userDetailsQuery.refetch,
    remove: userDetailsQuery.remove,
  };
};

export default useUserDetails;
