import { createContext, useState } from 'react';

export const citizenRecordsContext = createContext({
  records: [],
  changeRecords: () => null,
});

export const CitizenRecordsProvider = ({ children }) => {
  const [records, setRecords] = useState([]);

  const handleChangeRecords = (records) => {
    setRecords(records);
  };

  return (
    <citizenRecordsContext.Provider
      value={{
        records,
        changeRecords: handleChangeRecords,
      }}>
      {children}
    </citizenRecordsContext.Provider>
  );
};
