import { useContext } from "react";

import NotificationContext from "./NotificationContext";
import { IDuration, IBannerStyle } from "./typeDefinition";

interface INotificationProps {
  message: string;
  description?: string;
  duration?: IDuration;
}

const useNotification = () => {
  const { onSetNotificationSettings } = useContext(NotificationContext);

  const showNotification = (style: IBannerStyle) => ({
    message,
    description,
    duration,
  }: INotificationProps) => {
    onSetNotificationSettings(message, style, duration);
  };

  return {
    error: showNotification("error"),
    success: showNotification("success"),
    warn: showNotification("warning"),
    info: showNotification("info"),
  };
};

export default useNotification;
