import React from 'react';

import Routes from './routes';
import { NotificationProvider } from 'reusables/NotificationBanner';
import { AdminNavProvider } from 'context/admin-nav-context';
import { CitizenRecordsProvider } from 'context/citizen-record-context';

function App() {
  return (
    <div className="App">
      <CitizenRecordsProvider>
        <AdminNavProvider>
          <NotificationProvider>
            <Routes />
          </NotificationProvider>
        </AdminNavProvider>
      </CitizenRecordsProvider>
    </div>
  );
}

export default App;
