import React from 'react';
import PropTypes from 'prop-types';
import { ReactQueryConfigProvider, QueryCache } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from 'theme';
import { ConfirmProvider } from 'material-ui-confirm';

import { AuthProvider } from './auth-context';

const queryCache = new QueryCache();

// Override some react query defaults to suit
// our use case.
// Feel free to override these for your request's usecase
// if needed
const reactQueryConfig = {
  queries: {
    // react query refetches on window focus to avoid
    // stale data, but that doesn't apply to us
    refetchOnWindowFocus: false,
    retry(failureCount, error) {
      if (error.status === (404 || 401)) return false;
      if (failureCount < 2) return true;
      return false;
    },
    // Setup for this isn't complete, disabling for now
    // useErrorBoundary: true,
  },
  mutations: {
    // in order to avoid unhandled errors, if all you do
    // is show an alert, using the `error` returned from `useMutation`
    // for example
    throwOnError: false,
  },
};
function AppProviders({ children }) {
  return (
    <ReactQueryConfigProvider config={reactQueryConfig} queryCache={queryCache}>
      <Router>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <ConfirmProvider>{children}</ConfirmProvider>
          </ThemeProvider>
        </AuthProvider>
      </Router>
    </ReactQueryConfigProvider>
  );
}

AppProviders.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AppProviders };
