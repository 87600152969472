// import { createMuiTheme } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  typography: {
    button: {
      fontSize: '1rem',
      textTransform: 'none',
    },
    fontFamily: ['Nunito Sans', 'Roboto', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: '#930100',
      light: '#FbF474',
      muted: '#A6ABB8',
    },
    secondary: {
      main: '#670000',
    },
  },
  zIndex: {
    modal: 5000,
  },
  overrides: {
    MuiTypography: {
      colorTextPrimary: '#3A3434',
      colorTextSecondary: '#616161',
    },
    MuiAvatar: {
      root: {
        fontSize: '1em',
      },
      colorDefault: {
        backgroundColor: '#007BFC',
      },
    },
    MuiListItem: {
      gutters: {
        paddingLeft: 16,
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'start',
        marginBottom: 48,
      },
    },
    MuiTableCell: {
      root: {
        padding: 8,
      },
    },
    MuiTableRow: {
      hover: {
        '&:hover': {
          backgroundColor: 'rgba(147, 1, 0, 0.05) !important',
        },
      },
    },
    MuiFormHelperText: {
      root: {
        margin: 0,
      },
      contained: {
        margin: `0 !important`,
      },
    },
  },
});
