import { useMutation } from 'react-query';

import { client } from 'utils/api-client';
import { useNotification } from 'reusables/NotificationBanner';

export default function useLogin() {
  const notification = useNotification();

  return useMutation((values) => client('auth/login/', { data: values }).then((res) => res), {
    onError: (error) => {
      notification.error({
        message: error?.detail,
      });
    },
  });
}
