import React, { ReactNode } from "react";

import useUserDetails from "hooks/userDetailsHooks";

export const CITIZEN_STATUS = {
  PENDING: "PENDING",
  COMPLETED: "COMPLETED",
};

const SplashScreenView = ({ children }: { children: ReactNode }) => {
  useUserDetails();

  return <>{children}</>;
};

export default SplashScreenView;
