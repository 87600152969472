import React, { useCallback, useState } from 'react';

const AdminNavContext = React.createContext();

const AdminNavProvider = (props) => {
  const [navInfo, setNavInfo] = useState({
    title: '',
    breadcrumb: [],
  });

  const updateNavInfo = useCallback((info) => {
    setNavInfo((state) => ({
      ...state,
      ...info,
    }));
  }, []);

  return (
    <AdminNavContext.Provider
      value={{
        navInfo,
        updateNavInfo,
      }}
      {...props}
    />
  );
};

const useAdminNav = () => React.useContext(AdminNavContext);

export { AdminNavProvider, useAdminNav };
